<template>
  <div v-if="getTimezoneDone">
    <div class="analytic-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h1 class="analytic-heading">
              UTM Analytics
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="showRegenerate">
            <v-btn class="ml-auto" color="primary" @click="doCalculate">
              Recalculate
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showExport">
          <v-col cols="12">
            <PickDatePopover
              :handler="pickedDate"
              :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
              class="mr-8"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items1"
              class="elevation-1"
              hide-default-footer
              :items-per-page="-1"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <div v-else class="analytic-loading">
    <Loading />
  </div>
</template>

<script>
import PickDatePopover from '../analytics/components/PickDatePopoverNoCompare';
import Loading from '@/components/Loading';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';
import moment from 'moment';
import analyticRequest from '@/apis/request/analyticRequest';
export default {
  components: {
    Loading,
    PickDatePopover,
  },
  data: function() {
    let query = this.$route.query;
    return {
      isLoadingReport: false,
      stores: query?.stores?.split(',') || [],
      getTimezoneDone: true,
      search: query.search || '',
      items: [
        {
          key: 'Product has sale',
          value: 'true',
        },
        {
          key: 'Product no sale',
          value: 'false',
        },
        {
          key: 'All products',
          value: 'all',
        },
      ],
      productHasSale: query?.sales || 'true',
      filters: [
        {
          key: 'Total Sales',
          value: 'SHOP',
        },
        {
          key: 'Product',
          value: 'PRODUCT',
        },
        {
          key: 'Variant option',
          value: 'VARIANT',
        },
        {
          key: 'Sale over time',
          value: 'SHOP_SALES_OVERTIME',
        },
        {
          key: 'Variant SKU',
          value: 'VARIANT_SKU',
        },
        {
          key: 'Product vendor',
          value: 'PRODUCT_VENDOR',
        },
        {
          key: 'Product type',
          value: 'PRODUCT_TYPE',
        },
      ],
      filter: query.filter || 'SHOP',
      dateRange: {
        from: null,
        to: null,
      },
      headers_first: [
        {
          text: 'Revenue',
          align: 'start',
          sortable: false,
          value: 'revenue',
        },
        {
          text: 'Basecost',
          align: 'start',
          sortable: false,
          value: 'basecost',
        },
        {
          text: 'Handling',
          align: 'start',
          sortable: false,
          value: 'handling',
        },
        {
          text: 'Dispute',
          align: 'start',
          sortable: false,
          value: 'dispute',
        },
        {
          text: 'Profit',
          align: 'start',
          sortable: false,
          value: 'profit',
        },
        {
          text: 'Orders',
          align: 'start',
          sortable: false,
          value: 'orders',
        },
        {
          text: 'AOV',
          align: 'start',
          sortable: false,
          value: 'aov',
        },
        {
          text: 'CR',
          align: 'start',
          sortable: false,
          value: 'cr',
        },
      ],
      headers: [
        {
          text: 'UTM Source',
          align: 'start',
          sortable: false,
          value: 'utm_source',
        },
        {
          text: 'Views',
          align: 'start',
          sortable: false,
          value: 'view_content',
        },
        {
          text: 'Add to Cart',
          align: 'start',
          sortable: false,
          value: 'add_to_cart',
        },
        {
          text: 'Reached checkout',
          align: 'start',
          sortable: false,
          value: 'reached_checkout',
        },
        {
          text: 'Orders',
          align: 'start',
          sortable: false,
          value: 'orders',
        },
        {
          text: 'CR',
          align: 'start',
          sortable: false,
          value: 'cr',
        },
      ],
      items0: [
        // {
        //   cr: 1,
        //   aov: 2,
        //   orders: 0,
        //   revenue: 0,
        //   profit: 0,
        //   hold: 0,
        //   basecost: 0,
        //   handling: 0,
        //   dispute: 0,
        // },
      ],
      items1: [
        // {
        //   datetime: 'Total',
        //   order: '95',
        //   revenue: '350',
        //   basecost: '56',
        //   handing: '12',
        //   hold: '0',
        //   dispute: '5',
        //   profit: '277',
        // },
        // {
        //   test: '234',
        //   datetime: '6/19/2023',
        //   order: '30',
        //   revenue: '100',
        //   basecost: '28',
        //   handing: '6',
        //   hold: '0',
        //   dispute: '2.5',
        //   profit: '63.5',
        // },
        // {
        //   datetime: '6/19/2023',
        //   order: '65',
        //   revenue: '250',
        //   basecost: '28',
        //   handing: '6',
        //   hold: '0',
        //   dispute: '2.5',
        //   profit: '213.5',
        // },
      ],
      dates: ['2019-09-10', '2019-09-20'],
      showExport: true,
      showDatePicker: false,
      showRegenerate: false,
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  async created() {
    await this.getUtmAnalytic();
  },
  methods: {
    async getUtmAnalytic() {
      let fromTime = this.dateRange.from
        ? moment(this.dateRange.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.pagePreference.standardTimezone || 'America/Los_Angeles')
            .format('YYYY-MM-DD');
      let toTime = this.dateRange.to
        ? moment(this.dateRange.to).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone || 'America/Los_Angeles')
            .format('YYYY-MM-DD');
      let data = await analyticRequest.get(`/actions/utm_analytics`, {
        params: {
          from_time: fromTime,
          to_time: toTime,
          // stores: stores,
          timezone: this.pagePreference.standardTimezone || 'America/Los_Angeles',
        },
      });

      if (data?.data?.firstRow) this.items0 = [data?.data?.firstRow];

      if (data?.data?.results) this.items1 = data?.data?.results;
    },

    onSelectedStores(stores) {
      this.stores = stores;
    },
    pickedDate(dates) {
      this.dateRange = {
        from: dates.startDate,
        to: dates.endDate,
      };
      this.getUtmAnalytic();
    },
    async doCalculate() {
      let data = await analyticRequest.post(`/actions/regenate_profit`, {});
    },
    async exportCsv() {
      try {
        this.isLoadingReport = true;
        let query = this.$route.query;
        const url = 'products/sale-report/export-excel';

        let data = await analyticRequest.get(url, { params: query });
        let text = data.data;
        console.log(text);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'sale-report.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        console.log(error);
      }
      this.isLoadingReport = false;
    },
  },
  computed: {
    allStores() {
      return this.$store.getters.stores;
    },
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  watch: {
    filter: function(val) {},
    productHasSale: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: query?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: val,
        },
      });
    },
    search: async function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: val,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    stores: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: val?.join(','),
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    dateRange: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
          to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
  },
  destroyed() {
    // No remove auto refresh
    localStorage.removeItem(STORAGE_NAME.SALE_REPORT_QUERY);
  },
};
</script>

<style lang="scss" scoped>
.website-page .website-container {
  max-width: unset !important;
}

.analytic-sale-report {
  text-align: center;
}

.select-filter {
  max-width: 200px;
  min-width: 200px;
}

.analytic-container .l-chart-container {
  .text-black {
    color: #000000;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-small {
    font-size: 12px;
  }

  .l-chart-title {
    font-size: 16px;
  }

  .sub-chart-container {
    height: 100%;
    padding: 16px;
  }

  .sub-chart-container {
    // transform: scale(1.01);

    // margin: -8px;
    .on-hover {
      transition: all 0.5s;
    }
  }
}

.v-text-field {
  margin-top: 0px !important;
}

.analytic-loading {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
